$color1: rgb(253, 221, 62);
$color2: rgb(12, 230, 130);
$color3: rgb(237, 237, 237);
$color4: rgba(253, 221, 62, 0.574);
$color5: rgba(12, 230, 128, 0.466);

/*  BOOTSTRAP */
$primary                    : $color2;

$navbar-light-color         : $color2;
$navbar-light-hover-color   : $color1;
$navbar-light-active        : $color1;
$font-family-sans-serif     : "Jost";


/* $border-radius              : 2rem; */
$input-btn-padding-y        : 1rem;
$input-btn-padding-x        : 2rem;

/* SWEET ALERTS */
$swal2-confirm-button-background-color  : $color1;
$swal2-cancel-button-background-color   : $color2;
$swal2-question                         : $color1;

/* SWIPER */
$themeColor                 : $color1;